/* eslint-disable no-template-curly-in-string */
export const comicsLocalizationEN = {
  searchItems: 'Search items',
  descriptionComics: 'Collect unique prizes in the new Event and get bonus skins and gifts',
  bonusHeader: 'Gifts in every case!',
  bonusDescription:
    'Get free cases and skins when you open cases. Hundreds of cool gifts are already waiting for you in the new event!',

  bannerInfo: 'Search for gifts',
  superPrizeBonus: 'An incredible prize awaits the one who collects prizes in all categories!',
  findItems: 'Items found',
  youPrize: 'Your prize',
  watchReplay: 'Watch replay',
  replay: 'Replay',
  progressBonusCase: 'Progress to the category bonus case',
  superPrizeBonusBlocked: 'Collect all the items and get the <1>Superprize</1>',
  superPrizeBonusAvailable: '<0>Superprize</0> is yours!<br/>Open it faster!',
  yourPrize: 'Your prize',
  searchGifts: 'Search for gifts',
  comicsHeroes: 'Operation: Dark Side',
  descriptionLRDragons:
    'Here you can view tasks and rewards for them, as well as spend earned coins',
  moreDetails: 'More details',
  weeklyTasks: 'Weekly tasks',
  '${count} ${left} ${days}': '{{count}} {{days}} {{left}}',
  stageTitle: 'Complete simple quests and get prizes!',
  daily: 'Daily',
  simpleQuests: 'Six simple quests that are updated every 24 hours.',
  completeQuest: 'For completing one quest, you will receive 1 <1>copper coin</1>.',
  completeDailyQuest: 'Daily quests are completed in any order.',
  weekly: 'Weekly',
  difficultQuests: 'Six more difficult quests that are updated once a week.',
  completeDifficultQuests: 'For completing one quest, you will receive 1 <1>silver coin</1>.',
  completeWeeklyDifficultQuests: 'Weekly quests are completed in a strict sequence.',
  special: 'Special',
  availableQuest: 'Available after completing all <1>weekly</1> quests.',
  completeSpecialQuest:
    'For completing the <1>special</1> quest, you will receive 1 <1>golden coin</1>.',
  blockedSpecialQuest:
    "You won't know what the special quest is until you complete all the <1>weekly</1> quests!",
  remember:
    'Remember! If you do not complete the secret quest by the end of the week, it will disappear.',
  whatCoins: 'How to use coins?',
  whatCoinsDescription:
    'coins make it possible to open bonus cases and get cool rewards in them! Like quests, coins are divided into three types: <1>copper</1>, <5>silver</5> and <3>golder</3>. Each type of coins gives access to opening unique  cases!',
  caseShop: 'Case shop',
  draw: 'DRAW IN PROGRESS',
  dailyQuests: 'Daily quests',
  prize: 'Prize',
  takePrize: 'Take prize',
  completed: 'Completed',
  finishEvent: 'The event is over, but have time to spend coins',
  spendCoins: 'Spend the coins on cases',
  giftsFound: 'Gifts found',
  getItem: 'How to get an item?',
  getItemDesc:
    'Each category of cases contains its own unique item, which you can get simply by opening the cases of that category',
  yourCoins: 'Your coins',
  close: 'close',
  topPlayers: 'Top 3 players',
  get: 'Get',
  giftFound: 'Gifts found',
  skinsWin: 'Skins Win',
  overallStatistics: 'Overall statistics',
  collected: 'Collected',
  you: 'You',
  comicsCollection: 'Comics Collection',
  missions: 'Missions',
  place: 'place',
};

import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Container, Description, Title, Rules } from './stage1.styles';
import { StageHeader } from '../stage-header';
import { eventImagesPath } from '../../../assets';

export const Stage1 = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <StageHeader />
      <img src={eventImagesPath.slider1Path} height={100} alt="" />
      <Description>
        <Title>{t('daily')}</Title>
        <Rules>
          <span>{t('simpleQuests')}</span>
          <span>
            <Trans i18nKey="completeQuest" t={t}>
              For completing one quest, you will receive 1
              <span className="accent">copper coin</span>
            </Trans>
          </span>
          <span>{t('completeDailyQuest')}</span>
        </Rules>
      </Description>
    </Container>
  );
};

import React, { useEffect, useState } from 'react';

import { useUser } from 'core/User';
import {
  actionRequestCaseList,
  actionRequestCategory,
  actionSetSelectedCategory,
} from 'games/CaseGame/ducks';
import { useDispatch } from 'react-redux';
import { TabBarCategory, TabItem, TabItemSkeleton } from './CategoryList.styles';
import { useGetCategoryData, useGetSelectedCategory, useGetSortParams } from 'games/CaseGame/hooks';
import { actionRequestEventCategory } from 'modules/event-module/comics-event/duck/default-event-duck';
import { useGetCategoryEvent } from 'modules/event-module/comics-event/hooks';

const empty = new Array(12).fill(0).map((_, index) => index);

export const CategoryList = React.memo(() => {
  const { categoryData } = useGetCategoryData();
  const { isLoadingEvent, eventCategory } = useGetCategoryEvent();
  const { user } = useUser();
  const { selectedCategory } = useGetSelectedCategory();
  const { sortBy, maxPrice, minPrice, query } = useGetSortParams();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(selectedCategory);

  useEffect(() => {
    dispatch(actionRequestCategory());
    dispatch(actionRequestEventCategory());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const tabHandler = (id: string) => () => {
    if (id !== selected) {
      if (!user?.id && id === 'free') return;
      dispatch(
        actionRequestCaseList({
          id,
          filterParams: { sortBy, maxPrice, minPrice, query },
        })
      );
      setSelected(id);
      dispatch(actionSetSelectedCategory(id === 'free' ? 'all' : id));
      //NOTE: added for saving category when you choose case, so if you go from free category, and open it free category will close, but if we will save value, user will have possibility to pass this block
    }
  };

  if (categoryData.isLoading && isLoadingEvent) {
    return (
      <TabBarCategory className="tabBar tablet">
        {empty.map(value => (
          <TabItemSkeleton key={value} className="skeleton" />
        ))}
      </TabBarCategory>
    );
  }

  return (
    categoryData?.data?.length > 0 && (
      <TabBarCategory className="tabBar tablet">
        {/* {categoryData.data.map(type => ( */}
        {[categoryData.data[0], ...eventCategory, ...categoryData.data.slice(1)].map(type => (
          <TabItem
            data-disable={type.id === selected && 'disable'}
            data-event={Number(type.id) === 19}
            onClick={tabHandler(type.id)}
            key={type.id}
            className={`tab-item  ${type.id.toString() === selected.toString() && 'active'} ${
              !user?.id && type.id === 'free' ? 'blocked' : ''
            }`}
          >
            {type.name}
          </TabItem>
        ))}
      </TabBarCategory>
    )
  );
});

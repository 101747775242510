export default {
  RULES: 'RULES',
  Chat: 'Chat',
  GAME: 'GAME',
  Top: 'Top',
  HISTORY: 'HISTORY',
  '/refill': 'Refill',
  '/refill/:type/giftcards': 'Refill',
  '/refill/:type/:name': 'Refill',
  '/withdrawal': 'Withdrawal',
  '/withdrawal/method': '$t(/withdrawal)',
  '/withdrawal/method/:method': '$t(/withdrawal)',
  '/withdrawal/:method': '$t(/withdrawal)',
  '/withdrawal/verify': 'Identification',
  '/withdrawal/method/verify': 'Identification',
  '/lottery': 'Raffle history',
  '/chat': 'Chat',
  //caseBattle
  '/case-battle': 'Case Battle',
  '/case-battle/:battleId': 'Case Battle',
  '/case-battle/create-battle': 'Create Game',
  '/case-battle/history': 'History',
  //event
  '/event': 'Operation: Dark Side',
  //classic
  '/classic': 'Classic',
  '/classic/history': 'History Classic',
  '/classic/top': 'Top Classic',
  '/classic/rules': 'Rules Classic',
  '/classic/lottery': '$t(/lottery)',
  '/marketplace/classic/virtual': 'Coins',
  '/marketplace/classic/inventory': 'Skins',

  //fast
  '/fast': 'Fast',
  '/fast/history': 'History Fast',
  '/fast/top': 'Top Fast',
  '/fast/rules': 'Rules Fast',
  '/fast/lottery': '$t(/lottery)',
  '/marketplace/fast/virtual': 'Coins',
  '/marketplace/fast/inventory': 'Skins',

  //hilo
  '/hilo': 'Hi Lo',
  '/hilo/rules': 'Rules Hi Lo',
  '/hilo/lottery': '$t(/lottery)',

  //crash
  '/crash': 'Crash',
  '/crash/history': 'History Crash',
  '/crash/top': 'Top Crash',
  '/crash/rules': 'Rules Crash',
  '/crash/lottery': '$t(/lottery)',

  //double
  '/double': 'Double',
  '/double/history': 'History Double',
  '/double/top': 'Top Double',
  '/double/rules': 'Rules Double',
  '/double/lottery': '$t(/lottery)',

  //x50
  '/x50': 'X50',
  '/x50/rules': 'Rules X50',
  '/x50/lottery': '$t(/lottery)',

  //Baccarat
  '/baccarat': 'Baccarat',
  '/baccarat/history': 'History Baccarat',
  '/baccarat/rules': 'Rules Baccarat',
  '/baccarat/lottery': '$t(/lottery)',

  //Wheel
  '/wheel': 'Wheel',
  '/wheel/rules': 'Rules Wheel',
  '/wheel/lottery': '$t(/lottery)',

  //tower
  '/tower': 'Tower',
  '/tower/history': 'History Tower',
  '/tower/top': 'Top Tower',
  '/tower/rules': 'Rules Tower',
  '/tower/lottery': '$t(/lottery)',

  //cases
  '/cases': 'Cases',
  '/cases/history': 'History Cases',
  '/cases/rules': 'Rules Cases',
  '/cases/lottery': '$t(/lottery)',
  '/cases/item/:itemId': 'Item Information',

  //slot
  '/slot': 'Slot',
  '/slot/history': 'History Slot',
  '/slot/rules': 'Rules Slot',
  '/slot/lottery': '$t(/lottery)',

  //craft
  '/craft': 'Craft',
  '/craft/history': 'History Craft',
  '/craft/rules': 'Rules Craft',
  '/craft/lottery': '$t(/lottery)',

  //poggi
  '/poggi': 'Poggi',
  '/poggi/history': 'History Poggi',
  '/poggi/rules': 'Rules Poggi',
  '/poggi/lottery': '$t(/lottery)',

  //lobby
  '/': 'Games',

  // profile
  '/personal': 'Account',
  '/personal/statistics': 'Statistics',
  '/personal/balance/cashback': 'Cashback',
  '/personal/levels': 'Levels',
  '/personal/referral/campaigns': 'Referral system',
  '/personal/referral': 'Referral system',
  '/personal/balance': 'Balance',
  '/personal/notification': 'Notifications',
  '/personal/settings': 'Settings',
  '/personal/settings/trading': 'Market settings',
  '/personal/settings/sounds': 'Sounds',
  '/personal/settings/privacy': 'Privacy',
  '/personal/settings/social': 'Social',
  '/personal/balance/bonus-code': 'Bonuses',
  '/personal/balance/transaction': 'Transactions',
  '/personal/self-exclusion': 'Self-exclusion',

  //leader race
  '/leaders-race-next': 'Leaders Race',
  '/leaders-race-next/tournament/regular': '$t(/leaders-race-next)',
  '/leaders-race-next/tournament/fast': '$t(/leaders-race-next)',
  '/leaders-race-next/tournament/great': '$t(/leaders-race-next)',
  '/leaders-race-next/tournament': '$t(/leaders-race-next)',
  '/leaders-race-next/quest': '$t(/leaders-race-next)',
  '/leaders-race-next/raffles/active': '$t(/leaders-race-next)',
  '/leaders-race-next/raffles/history': '$t(/leaders-race-next)',
  '/leaders-race-next/raffles/rules': '$t(/leaders-race-next)',
  '/leaders-race-next/raffles': '$t(/leaders-race-next)',
  '/leaders-race-next/raffles/active/two-week': '$t(/leaders-race-next)',
  '/leaders-race-next/raffles/active/two-month': '$t(/leaders-race-next)',
  '/leaders-race-next/raffles/history/two-week': '$t(/leaders-race-next)',
  '/leaders-race-next/raffles/history/two-month': '$t(/leaders-race-next)',
  '/leaders-race-next/raffles/history/:raffleType/:raffleId': '$t(/leaders-race-next)',
  '/leaders-race-next/tournament/history/regular': '$t(/leaders-race-next)',
  '/leaders-race-next/tournament/history/fast': '$t(/leaders-race-next)',
  '/leaders-race-next/tournament/history/great': '$t(/leaders-race-next)',
  '/leaders-race-next/tournament/history/:raceType': '$t(/leaders-race-next)',
  '/leaders-race-next/store': '$t(/leaders-race-next)',
  '/leaders-race-next/raffles/active/:raffleType': '$t(/leaders-race-next)',
  '/leaders-race-next/landing': '$t(/leaders-race-next)',
  //leader race-tabs
  '/leaders-race-next/store/dota2_tabs': 'Dota2',
  '/leaders-race-next/store_tabs': 'Csgo',
  '/leaders-race-next/tournament_tabs': 'all',
  '/leaders-race-next/tournament/regular_tabs': 'Regular',
  '/leaders-race-next/tournament/fast_tabs': 'Fast',
  '/leaders-race-next/tournament/great_tabs': 'Great',
  '/leaders-race-next/raffles/active_tabs': '$t(active)',
  '/leaders-race-next/raffles/history_tabs': '$t(HISTORY)',
  '/leaders-race-next/raffles/rules_tabs': '$t(RULES)',

  //assistance
  '/assistance': 'FAQ / Support',

  //faq
  '/faq': 'FAQ',
  '/faq/common': 'FAQ',
  '/faq/bet-questions': 'FAQ',
  '/faq/getting-your-win': 'FAQ',
  '/faq/referral-program': 'FAQ',
  '/faq/service-commission': 'FAQ',
  '/faq/chat-rules': 'FAQ',

  // accounts
  '/accounts': 'Account',
  '/accounts/statistics': 'Statistics',
  '/accounts/race-activity': 'Leaders race',

  // inventory
  '/inventory': 'Inventory',
  '/inventory/fast': 'Fast inventory',
  '/inventory/exchange': 'Exchange',

  '/settings': 'Settings',
  '/balance': 'Balance',
  '/marketplace': 'Marketplace',
  '/market': '$t(/marketplace)',
  '/market/orders': '$t(/marketplace)',

  '/notifications': 'Notifications',
  '/auth': 'Authorization',
  // '/faucet': 'Free Coins',
  '/refill-bonus': 'Refill bonus',
  '/promo': 'CSGOFAST',

  '/privacy-policy': 'Privacy policy',
  '/tos': 'Terms and conditions',

  // game tabs
  'game-tab_rules': 'How to play <br/>in $t({{routeName}})?',

  //Toolbar locales
  chat: 'Chat',
  sounds: 'Sounds',
  history: 'History',
  rules: 'How to play?',
  stats: 'Statistic',
  top: 'Top',
};

import React, { FC, memo, MouseEvent, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { CurrencyText } from '@components/currency';
import { PluralizeTimer, Timer } from '@components/TimerEvent';
import { useDialog } from 'core/hooks';
import { getExteriorByFloat } from 'core/utils/colors.utils';
import { useSalesElement } from '../../../../../hooks';
import { IMarketplaceElement } from '../../../../../interfaces';
import { OrdersSaleElementControllers } from './orders-sale-element-controllers';
import { BIDDING_STATUSES, FINISH_STATUSES, OrderStatusEnum } from '../../../../../configs';
import { OrdersSaleElementSideMenu } from './orders-sale-element-side-menu';
import { MarketplaceDialogs } from '../../../../../marketplace-dialogs.config';
import { skinItemImage } from 'core/utils/skinItemImage.utils';
import { skinStickerImage } from 'core/utils/skinStickerImage.utils';

interface IOrdersSaleElement {
  id: string;
}

interface IOrdersSaleElementStyle {
  rarity?: string;
  next: string;
  current: string;
  progress?: number;
  delta?: number;
}

export const OrdersSaleElement: FC<IOrdersSaleElement> = memo(({ id }) => {
  const [disabled, set] = useState<boolean>(false);
  const { handleToggleDialog } = useDialog(MarketplaceDialogs.MARKETPLACE_STORE_SALE);
  const { item } = useSalesElement<IMarketplaceElement>(id);
  const { t } = useTranslation('marketplace');

  const onClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      if (
        [...BIDDING_STATUSES, OrderStatusEnum.WAIT_FOR_CONFIRM, OrderStatusEnum.PAUSED].includes(
          item.status
        )
      ) {
        return handleToggleDialog(MarketplaceDialogs.MARKETPLACE_STORE_SALE, {
          id,
        })();
      }
      return handleToggleDialog(MarketplaceDialogs.INVENTORY_SELLER_TRADE_DIALOG, {
        id,
        immediate: true,
      })();
    },
    [item.status, id, handleToggleDialog]
  );

  useEffect(() => {
    if (item.status !== OrderStatusEnum.PAUSED) return;
    set(() => true);
    const timer = setTimeout(() => set(() => false), Date.parse(item.nextStatusAt) - Date.now());
    return () => clearTimeout(timer);
  }, [item, set]);

  return (
    <Container
      rarity={item.items?.[0].baseItem.color}
      next={item.nextStatusAt}
      current={item.statusAt}
      className={item.status}
    >
      <OrdersSaleElementSideMenu status={item.status} id={item.id} disabled={disabled} />
      <div className="wrapper" onClick={onClick}>
        <div className="progress" />
        <div className="thumbnails">
          <img
            srcSet={`${skinItemImage(item.items?.[0]?.baseItem.icon, 80, 60)} 1x, ${skinItemImage(
              item.items?.[0]?.baseItem.icon,
              160,
              120
            )} 2x`}
            src={skinItemImage(item.items?.[0]?.baseItem.icon, 80, 60)}
            alt=""
          />
        </div>
        <div className="stickers">
          {item.items?.[0]?.stickers?.map(({ icon }: { icon: string }, i: number) => (
            <img key={i} src={skinStickerImage(icon)} alt="" />
          ))}
        </div>
        <div className="until-time">
          {OrderStatusEnum.NEW !== item.status && !FINISH_STATUSES.includes(item.status) && (
            <span>
              <Timer finishEvent={item.nextStatusAt} format="mm:ss" />
            </span>
          )}
        </div>

        <div className="detailed">
          <div className="exterior">
            {t(`exterior-short.${(item.items?.[0]?.baseItem.exterior ?? '').toLowerCase()}`)}
          </div>
          <div className={`float ${getExteriorByFloat(item.items?.[0]?.float)}`}>
            {!!item.items?.[0]?.float && item.items?.[0]?.float?.toFixed(4)}
          </div>
          {OrderStatusEnum.NEW === item.status && (
            <div className="timeline">
              <PluralizeTimer finishEvent={item.nextStatusAt} />
            </div>
          )}
          <div className="display-name">{item.items?.[0]?.baseItem.name}</div>
          <div className="display-price">
            <CurrencyText value={item.sellerPrice} />
          </div>
        </div>

        <OrdersSaleElementControllers status={item.status} id={item.id} disabled={disabled} />

        <div className="statuses">
          {OrderStatusEnum.NEW !== item.status && (
            <span>{t('statuses.seller', { context: item.status })}</span>
          )}
        </div>
      </div>
    </Container>
  );
});

const Container = styled.div.attrs<IOrdersSaleElementStyle>(({ next, current }) => ({
  delta: (Date.parse(next) - Date.now()).toFixed(0),
  progress: (dayjs(current).diff(dayjs()) / dayjs(current).diff(next)) * 100,
}))<IOrdersSaleElementStyle>`
  position: relative;

  &.wait-for-confirm,
  &.wait-for-trade,
  &.wait-for-buyer-accept {
    order: -3;
  }

  &.new,
  &.first-bid,
  &.second-bid,
  &.third-plus-bid {
    order: -2;
  }

  &.paused {
    order: -1;
  }

  & .wrapper {
    display: grid;
    grid-auto-flow: column;
    grid-template: 'thumbnails detailed' 'stickers controllers' 'until-time statuses';
    grid-template-columns: 0.75fr 1fr;
    grid-auto-rows: 1fr;
    grid-gap: 0.5rem 1rem;
    width: 100%;
    height: 100%;
    min-height: 8.75rem;
    padding: 0.875rem;
    background: var(--color-dark-200);
    border-radius: var(--size-radius-m);
    position: relative;
    overflow: hidden;
    mask-image: radial-gradient(white, black);
    transition: transform 150ms ease-in-out;

    &:before {
      content: '';
      width: 1rem;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      border-radius: var(--size-radius-m) 0 0 var(--size-radius-m);
      box-shadow: inset 0.125rem 0 0 ${({ rarity }) => rarity || 'transparent'};
    }

    & .progress {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      animation-timing-function: linear;
      animation-name: slide;
      animation-fill-mode: forwards;
      background-color: var(--color-gray-300);
      animation-duration: ${({ delta }) => `${delta}ms`};
      transform: ${({ progress }) => `translate3d(-${progress.toFixed(2)}%, 0, -1px)`};
    }

    & .thumbnails,
    & .detailed {
      position: relative;
      z-index: 20;
      height: 100%;
    }

    & .thumbnails {
      grid-area: thumbnails;
      overflow: hidden;

      & img {
        align-self: center;
        justify-self: center;
        max-width: 100%;
        width: 100%;
        height: 4.25rem;
        object-fit: contain;
      }
    }

    & .stickers {
      grid-area: stickers;
      justify-self: center;
      align-self: center;
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: 1.5rem;
      grid-template-rows: 1.5rem;
      grid-gap: 0.5rem;

      & img {
        width: 1.5rem;
        height: 1.5rem;
        object-fit: contain;
      }
    }

    & .until-time {
      align-self: center;
      justify-self: center;

      & span {
        min-height: 1.5rem;
        font-size: 1.25rem;
        line-height: 1.5rem;
        color: var(--color-white);
      }
    }

    & .detailed {
      grid-area: detailed;
      display: grid;
      grid-template-columns: min-content min-content 1fr;
      grid-gap: 0.5rem 0.75rem;

      & .exterior,
      & .timeline,
      & .float {
        height: 0.875rem;
        font-weight: bold;
        font-size: 0.625rem;
        line-height: 0.875rem;
        letter-spacing: 0.3px;
        white-space: nowrap;
      }

      & .exterior {
        color: var(--color-gray-200);
      }

      & .float {
        color: transparent;

        &.factory-new {
          color: var(--color-exterior-factory-new);
        }

        &.minimal-wear {
          color: var(--color-exterior-minimal-wear);
        }

        &.field-tested {
          color: var(--color-exterior-field-tested);
        }

        &.well-worn {
          color: var(--color-exterior-well-worn);
        }

        &.battle-scarred {
          color: var(--color-exterior-battle-scarred);
        }
      }

      & .timeline {
        color: var(--color-gray-200);
      }

      & .display-name,
      & .display-price {
        grid-column: 1 / 4;
        font-size: 0.875rem;
        letter-spacing: 0.24px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: var(--color-white);
      }

      & .display-name {
        align-self: center;
      }

      & .display-price {
        align-self: self-start;
        height: 1.75rem;

        & span {
          font-size: inherit;
          letter-spacing: inherit;
        }
      }
    }

    & .statuses {
      grid-area: statuses;
      align-self: center;

      & span {
        display: block;
        margin-top: 0.25rem;
        font-size: 0.75rem;
        line-height: 0.9375rem;
        letter-spacing: 0.3px;
        color: var(--color-white);
      }
    }
  }
`;

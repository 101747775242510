/* eslint-disable no-template-curly-in-string */
export const comicsLocalizationRU = {
  searchItems: 'Поиск предметов',
  descriptionComics: 'Собирай уникальные призы в новом Ивенте и получай бонусные скины и подарки',
  bonusHeader: 'Подарки в каждом кейсе!',
  bonusDescription:
    'Получайте бесплатные кейсы и скины при открытии кейсов. Сотни крутых подарков уже ждут вас в новом событии!',

  bannerInfo: 'Узнать о подарках',

  superPrizeBonus: 'Невероятный приз ждёт того, кто соберёт подарки во всех категориях!',
  findItems: 'Найдено предметов',
  youPrize: 'Ваш приз',
  watchReplay: 'Смотреть повтор',
  replay: 'Повтор',
  progressBonusCase: 'Прогресс до бонусного кейса категории',
  superPrizeBonusBlocked: 'Собери все предметы и получи <1>Супер-приз</1>',
  superPrizeBonusAvailable: '<0>Супер-приз</0> ваш!<br/>Откройте его скорее!',
  yourPrize: 'Ваш приз',
  searchGifts: 'Поиск подарков',

  descriptionLRDragons:
    'Здесь можно ознакомиться с заданиями и наградами за них, а также потратить заработанные монеты',
  moreDetails: 'Подробнее',
  weeklyTasks: 'Еженедельные задания',
  '${count} ${left} ${days}': '{{left}} {{count}} {{days}}',
  stageTitle: 'Выполняй простые задания и получай крутые призы!',
  daily: 'Ежедневный',
  simpleQuests: 'Шесть простых квестов, которые обновляются каждый день.',
  completeQuest: 'За выполнение квеста ты получишь 1 <1>медную монету</1>.',
  completeDailyQuest: 'Ежедневные квесты можно выполнять в любом порядке.',
  weekly: 'Еженедельный',
  difficultQuests: 'Шесть более сложных квестов, на их выполнение даётся неделя.',
  completeDifficultQuests: 'За выполнение квеста ты получишь 1 <1>серебряную моенту</1>.',
  completeWeeklyDifficultQuests: 'Еженедельные квесты проходятся по порядку.',
  special: 'Специальный',
  availableQuest: 'Доступен после выполнения всех <1>еженедельных</1> квестов.',
  completeSpecialQuest:
    'За выполнение <1>специального</1> квеста ты получишь 1 <1>золотую монету</1>.',
  blockedSpecialQuest:
    'Ты не узнаешь, что скрывается за секретным квестом, пока не выполнишь все <1>еженедельные</1>!',
  remember: 'Помни! Если не выполнить секретный квест до конца недели - он пропадёт.',
  whatCoins: 'Как использовать монеты?',
  whatCoinsDescription:
    'Монеты дают возможность открывать бонусные кейсы и получать из них крутые награды! Как и квесты, монеты бывают разных видов: <1>медные</1>, <5>серебряные</5> и <3>золотыe</3>. Каждый вид монет позволяет открыть свой тип бонусных кейсов!',
  caseShop: 'Магазин кейсов',
  draw: 'ИДËТ РОЗЫГРЫШ',
  dailyQuests: 'Ежедневные задания',
  prize: 'Приз',
  takePrize: 'Забрать приз',
  completed: 'Выполнено',
  finishEvent: 'Акция закончена, успей потратить заработанные монеты',
  spendCoins: 'Потратить монеты на кейсы',
  giftsFound: 'Подарков найдено',
  getItem: 'Как получить предмет?',
  getItemDesc:
    'Каждая категория кейсов содержит свой уникальный предмет, которые вы можете получить просто открывая кейсы этой категории',
  yourCoins: 'Твои монеты',
  close: 'закрыть',
  topPlayers: 'Топ 3 игроков',
  get: 'Получил',
  giftFound: 'Подарков найдено',
  skinsWin: 'Cкинов выиграно',
  overallStatistics: 'Общая статистика',
  collected: 'Разыграно',
  you: 'Вы',

  Quests: 'Квесты',
  place: 'место',
};

import styled from 'styled-components';
import { NavigationLink } from '@components/Common';
import { URLS } from 'services/constant-urls';
// import { eventImagesPath } from 'modules/event-module/comics-event/assets';

export const BalanceLink = styled(NavigationLink)`
  background: transparent;
  height: 88px;
  width: 100%;

  &:hover {
    background: transparent;
  }

  & > div:not(.leading) {
    margin-left: 1.2rem;
    padding-right: 5.5rem;

    & > span {
      color: var(--color-white);
      font-size: 1.8rem;
      font-weight: 400;
      text-wrap: nowrap;
      z-index: 20;

      &.event-title {
        color: #6dad52;
        font-size: 14px;
        font-weight: 700;
        padding: 10px 0 0;

        &.guest {
          padding: 0;
        }
      }
    }

    &::after {
      content: '\\e901';
      font-size: 0.9rem;
      color: var(--color-gray-100);
      font-family: icomoon;
      position: absolute;
      right: 0.65rem;
      top: 50%;
      transform: translateY(-50%) rotate(-90deg);
    }
  }
`;

export const Container = styled.div`
  border-bottom: 1px solid var(--color-gray-900);
  border-top: 1px solid var(--color-gray-900);
  position: relative;
  background: var(--color-dark-layout);

  & ${BalanceLink} {
    border: 0;
    position: relative;
    z-index: 10;
  }

  /* & .event-title {
    color: var(--color-red-300);
    font-size: 0.9rem;
    font-weight: bold;
    padding: 0 1rem 0 3.2rem;
    position: relative;
    z-index: 1;
  } */

  &.bonus-event {
    & .event-title {
      color: var(--color-white);
    }

    &::after,
    &::before {
      content: '';
      display: block;
      position: absolute;
    }

    &::before {
      top: 0;
      left: 0;
      background: radial-gradient(
        60.3% 60.3% at 50% 39.7%,
        var(--color-green-200) 0%,
        var(--color-green-300) 100%
      );
      width: 100%;
      height: 100%;
    }

    &::after {
      background: url(${URLS.refill.bonus}) no-repeat;
      height: 52px;
      right: 2.5rem;
      top: 1rem;
      width: 52px;
    }
  }
`;

export const StyledImage = styled.img`
  content: '';
  height: 58px;
  pointer-events: auto;
  position: absolute;
  right: 1px;
  top: 50%;
  transform: translateY(-50%);
  width: 58px;
  z-index: 20;
`;
